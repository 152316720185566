import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  ASYNC_REROUTE_PACKAGE,
  DOWNLOAD_EDI,
  GET_ASYNC_REROUTE_BATCHES_PROGRESS,
  GET_REROUTE_REASONS,
  PACKAGE_DETAILS,
  REROUTE_PACKAGE,
  SPLIT_PACKAGE,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { updateReroutedPackages } from 'store/Order/OrderSlice';

import {
  AsyncPackageReroutingRequest,
  AsyncPackageReroutingResponse,
  DownloadEdiRequest,
  DownloadEdiResponse,
  PackageDetails,
  PackageRerouteProgressListRequest,
  PackageRerouteProgressListResponse,
  PackageReroutingRequest,
  PackageReroutingResponse,
  RerouteReason,
  SplitPackageRequest,
  SplitPackageResponse,
} from './Types';

export const PackageApi = createApi({
  reducerPath: 'PackageApi',
  tagTypes: ['Package'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    reroutePackages: builder.mutation<PackageReroutingResponse, PackageReroutingRequest>({
      query: (data) => ({
        url: REROUTE_PACKAGE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Package', id: 'GET' }],
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: respData } = await queryFulfilled;
          const reroutedPackageIds =
            respData?.data?.package?.filter(({ status }) => status === 'ok').map(({ packageId }) => packageId) || [];
          let reroutedPackages = data.package.filter(({ packageId }) => reroutedPackageIds.includes(packageId));

          const packageIdsWithOnlyPartnerUpdate = respData?.data?.package
            ?.filter(
              ({ status, message }) =>
                status === 'fail' && message.includes('Print partner changed, but shipping method update failed'),
            )
            .map(({ packageId }) => packageId);

          if (packageIdsWithOnlyPartnerUpdate?.length) {
            const partnerOnlyPackages = data?.package
              ?.filter(({ packageId }) => packageIdsWithOnlyPartnerUpdate.includes(packageId))
              ?.map((pkg) => ({ ...pkg, noShippingUpdate: true }));
            if (partnerOnlyPackages?.length) reroutedPackages.push(...partnerOnlyPackages);
          }

          if (reroutedPackages.length) {
            dispatch(
              updateReroutedPackages({
                packages: reroutedPackages,
                // @ts-ignore
                shipmentMethods: await getState().shipmentMethod.shipmentMethods,
                newStatusUid: data.printJobStatusUid,
                deleteErrorTags: data.deleteErrorTags,
              }),
            );
          }
        } catch {}
      },
    }),
    asyncReroutePackages: builder.mutation<AsyncPackageReroutingResponse, AsyncPackageReroutingRequest>({
      query: (data) => ({
        url: ASYNC_REROUTE_PACKAGE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Package', id: 'GET' }],
    }),
    getAsyncRerouteBatchesProgress: builder.query<
      PackageRerouteProgressListResponse,
      PackageRerouteProgressListRequest
    >({
      query: (data) => ({
        url: GET_ASYNC_REROUTE_BATCHES_PROGRESS,
        method: 'POST',
        body: data,
      }),
      keepUnusedDataFor: 0,
    }),
    getPackageDetails: builder.query<PackageDetails | undefined, string>({
      query: (packageId) => ({
        url: PACKAGE_DETAILS,
        method: 'POST',
        body: { packageId: [packageId] },
      }),
      transformResponse: (resp: { data: { packageDetails: PackageDetails[] } }) => {
        return resp.data.packageDetails[0] || undefined;
      },
    }),
    getEDIDetails: builder.query<DownloadEdiResponse | undefined, DownloadEdiRequest>({
      query: (body) => ({
        url: DOWNLOAD_EDI,
        method: 'POST',
        body: { ...body },
      }),
      transformResponse: (resp: { data: DownloadEdiResponse }) => {
        return resp.data;
      },
    }),
    getRerouteReasons: builder.query<RerouteReason[] | undefined, null>({
      query: () => ({
        url: GET_REROUTE_REASONS,
        method: 'GET',
      }),
      keepUnusedDataFor: 86400, // 1-day in seconds
      transformResponse: (resp: { data: { rerouteReason: RerouteReason[] } }) => {
        return resp.data?.rerouteReason || [];
      },
    }),
    splitPackage: builder.mutation<SplitPackageResponse, SplitPackageRequest>({
      query: (data) => ({
        url: SPLIT_PACKAGE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Package', id: 'GET' }],
    }),
  }),
});

export const {
  useReroutePackagesMutation,
  useAsyncReroutePackagesMutation,
  useGetAsyncRerouteBatchesProgressQuery,
  useGetPackageDetailsQuery,
  useLazyGetEDIDetailsQuery,
  useGetRerouteReasonsQuery,
  useSplitPackageMutation,
} = PackageApi;
