export const GET_PHMT_CONFIGURATION = '/service/api/v1/print-house-management-tool/configuration';
export const GET_PHMT_PRINT_HOUSES = '/service/api/v1/print-house/list';
export const GET_PHMT_PRINT_HOUSE = '/service/api/v1/print-house-management-tool/print-house/get';
export const CREAT_PHMT_PRINT_HOUSE = '/service/api/v1/print-house-management-tool/print-house/create';
export const UPDATE_PHMT_PRINT_HOUSE = '/service/api/v1/print-house-management-tool/print-house/update';
export const MAP_PHMT_PRINT_HOUSE = '/service/api/v1/print-house-management-tool/print-house/map';
export const PRODUCTS_CATALOGUE = '/service/api/v1/print-house-schedule/model-list';
export const GET_WORKING_NON_WORKING_DAYS = '/service/api/v1/print-house/production-day/list';
export const UPDATE_PRINT_HOUSE_PARTNER_MANAGER_PREFIX = '/service/api/v1/print-house'; // /{printHouseUid}/partner-contact'
export const UPDATE_PRINT_HOUSE_PARTNER_MANAGER_SUFFIX = 'partner-contact';
export const GET_ACTIVE_PARTNER_COUNTRIES = '/service/api/v1/print-house-management-tool/active-printhouse-countries';
export const GET_COUNTRIES_HOLIDAYS = '/service/api/v1/country/holidays';
export const GET_TIMEZONES_LIST = '/service/api/timezones/list';
export const GET_PARTNER_CLIENTS = '/service/api/v1/print-house/clients';

/**
 * Production Time /SLA
 */
export const GET_PHMT_PRODUCTION_TIME = '/service/api/v1/print-house/production-time';
export const UPDATE_PHMT_STANDARD_PRODUCTION_TIME = '/service/api/v1/print-house/production-time/update-standard';
export const UPDATE_PHMT_PARTNER_SPECIFIC_PRODUCT_PRODUCTION_TIME =
  '/service/api/v1/print-house/production-time/update-partner-products';
export const UPDATE_PHMT_PARTNER_SPECIFIC_PRODUCT_PRODUCTION_TIME_TEMPORARY_PADDING =
  '/service/api/v1/print-house/production-time/update-temporary-padding';
export const GET_CLIENT_SPECIFIC_PRODUCTION_TIME = '/service/api/v1/print-house/production-time/client-specific/list';
export const UPDATE_CLIENT_SPECIFIC_PRODUCTION_TIME =
  '/service/api/v1/print-house/production-time/update-client-specific';
export const GET_O2D_CONFIG = '/service/api/v1/print-house-management-tool/order-to-delivery';
export const UPDATE_O2D_CONFIG = '/service/api/v1/print-house-management-tool/order-to-delivery';

/**
 * Delivery Time
 */
export const GET_PARTNER_DELIVERY_TIME_PADDING = '/service/api/v1/print-house/delivery-time-padding';
export const UPDATE_PARTNER_DELIVERY_TIME__TEMP_PADDING = '/service/api/v1/print-house/delivery-time-padding/cud-temp';

/**
 * Product Platform Proxy
 */
export const GET_PP_PRODUCT_MODELS = '/service/api/v1/product_platform_proxy/get-product-model-list';

/**
 * Audit Logs Bundle
 */
export const GET_AUDIT_LOGS = '/service/api/v2/audit-trail/logs/find';
export const CREATE_AUDIT_LOGS = '/service/api/v2/audit-trail/logs/create';
export const BATCH_CREATE_AUDIT_LOGS = '/service/api/v2/audit-trail/logs/batch-create';

/**
 * File Upload Scheduler
 */
export const FILE_UPLOAD_SCHEDULER_LIST = '/service/api/v1/print-house-schedule/list';
export const FILE_UPLOAD_SCHEDULER_TOGGLE = '/service/api/v1/print-house-schedule/toggle';
export const FILE_UPLOAD_SCHEDULER_BATCH_UPDATE = '/service/api/v1/print-house-schedule/batch-update';
export const FILE_UPLOAD_SCHEDULER_DELETE = '/service/api/v1/print-house-schedule/delete';

export const FILE_UPLOAD_SCHEDULER_LIST_V2 = '/service/api/v2/print-house-schedule/list';
export const FILE_UPLOAD_SCHEDULER_TOGGLE_V2 = '/service/api/v2/print-house-schedule/toggle';
export const FILE_UPLOAD_SCHEDULER_BATCH_UPDATE_V2 = '/service/api/v2/print-house-schedule/batch-update';
export const FILE_UPLOAD_SCHEDULER_DELETE_V2 = '/service/api/v2/print-house-schedule/delete';

/**
 * Production Days
 */

export const PRODUCTION_DAYS_LIST = '/service/api/v1/print-house/production-day/get-list';
export const PRODUCTION_DAYS_CREATE_UPDATE = '/service/api/v1/print-house/production-day/create-or-update';
export const IMPORT_PUBLIC_HOLIDAYS = '/service/api/v1/print-house/holidays/import';
export const REMOVE_IMPORTED_HOLIDAYS = '/service/api/v1/print-house/holidays/remove';

/**
 * FTP Status
 */
export const GET_PRINT_HOUSE_FTP_STATUS = '/service/api/v1/print-house/ftp-status';

/**
 * Print Plates
 */
export const GET_PRINT_PLATES = '/service/api/v1/print_plate/search';
export const GET_PRINT_PLATE_HISTORY = '/service/api/v1/print_plate/history';
export const SET_PRINT_PLATES_STATUS = '/service/api/v1/print_plate/set_status';
export const PRINT_PLATES_UPLOAD = '/service/api/v1/print_plate/upload';
export const PRINT_PLATES_FILE_APPLY_FIXUP = '/service/api/v1/print_plate/file/apply_fixup';
export const GET_PRINT_PLATE_STATUSES = '/service/api/v1/print_plate_statuses';
export const GET_PRINT_PLATE_BUILD_ENGINES = '/service/api/v1/print_plate_build_engines';
export const GET_PRINT_PLATE_ERROR_GROUPS = '/service/api/v1/print-plate/error-classification-list';

export const GET_PRINT_PLATE_IMPOSITION_DETAILS = '/service/api/v1/print_plate/imposition_details';
export const GET_PRINT_PLATE_REQUEST_PAYLOAD = '/service/api/v1/artifactor/list';
export const GET_COUNTRIES_LIST = '/service/api/v1/country/iso-code';
export const PRINT_JOBS_DETAILS = '/service/api/v1/print_job/list';

/**
 * GN Proxy to Identity V3 users
 */
export const GET_USER_NAMES = '/service/api/v1/gelato_user/users:search';

/**
 * Contact Notifications
 */
export const GET_NOTIFICATION_GROUPS = '/service/api/notifications/groups';
export const SEARCH_NOTIFICATION_GROUP_MEMBERSHIPS = '/service/api/notifications/groups/memberships/search';
export const SET_NOTIFICATION_GROUP_MEMBERSHIPS = '/service/api/notifications/groups/memberships';

/**
 * Print Job
 */
export const PRINT_JOB_TAG_LIST = '/service/api/v2/print_job/tag/list';
export const CREATE_PRINT_JOB_TAG = '/service/api/v2/print_job/tag/add';
export const DELETE_PRINT_JOB_TAG = '/service/api/v2/print_job/tag/remove';
export const ADD_BULK_PRINT_JOB_TAG = '/service/api/v2/print_job/tag/batch-add';
export const REMOVE_BULK_PRINT_JOB_TAG = '/service/api/v2/print_job/tag/batch-remove';
export const PRINT_JOB_STATUS_LIST = '/service/api/v1/print-job/status';
export const PRINT_JOB_FILTER_OPTIONS_CONFIG = '/service/admin/manage-print-job/configuration';
export const PRINT_JOB_EVENT_HISTORY = `/service/api/v3/manage_print_job/event_history`;
export const BUILD_AND_DOWNLOAD_PLATES = '/service/api/v1/prepare_plates/build_and_download_plates';
export const BUILD_AND_UPLOAD_PLATES = '/service/api/v1/prepare_plates/build_and_upload_plates';
export const RESEND_STOCK_ITEM = '/service/api/v1/stockable_product/create';
export const GET_STOCKABLE_PRODUCTS_BY_PRINTJOB = '/service/api/v1/stockable_product/by_print_job';

/**
 * Order list
 */
export const ORDER_LIST = '/service/api/v3/manage_print_job/search';
export const ORDER_ISSUES = '/service/api/v1/order/issues';
export const GELATO_CREATE_COMPANY_LIST = '/service/api/v3/manage_print_job/gelato_create_company/list';

/**
 * Promise info
 */
export const GET_PROMISE_INFO = '/service/admin/package-info/promise';
/**
 *  Package Reroute
 */
export const V2_GET_CAPABLE_PRINT_HOUSES = '/service/api/v2/print-house/capable-list/by-package';
export const V1_GET_CAPABLE_PRINT_HOUSES = '/service/api/v1/print-house/capable-list/by-package';
export const REROUTE_PACKAGE = '/service/api/v1/package/reroute';
export const ASYNC_REROUTE_PACKAGE = '/service/api/v1/package/reroute:bulk';
export const GET_ASYNC_REROUTE_BATCHES_PROGRESS = '/service/api/v1/package/reroute:bulk/progress/list';
export const SPLIT_PACKAGE = '/service/api/v1/package/split';
export const PACKAGE_DETAILS = '/service/api/v1/package/list';
export const CHANGE_PRINTJOB_STATUS = '/service/api/v2/print-job/set-status';
export const DOWNLOAD_EDI = '/service/api/v3/edi/get';
export const GET_REROUTE_REASONS = '/service/api/v1/package/reroute/reasons';
export const GET_ORDER_CANCELLATION_REASONS = '/service/api/v2/print-job/cancellation/reasons';
export const v1_CHANGE_PRINT_HOUSE = '/service/find_print_job/change_print_house';
export const v1_CHANGE_SHIPPING_METHOD = '/service/find_print_job/change_print_job_shipment_method';

/**
 * Apparel Management
 */
export const UPDATE_PARTNER_SKU_MAPPING = '/v1/partner-sku/mapping/update';
export const GET_PARTNER_CONNECT_API_SYNC_STATUS = '/v1/partner/settings/list';
export const UPDATE_PARTNER_CONNECT_API_SYNC_STATUS = '/v1/partner/settings/update';
export const GET_PARTNER_CONNECT_API_UNSYNCED_CHANGES = 'v1/mapping-updates/list/pending';
export const TRIGGER_FETCH_PRODUCTS_FROM_PP = 'v1/action/trigger';
export const GET_FETCH_PRODUCTS_FROM_PP_STATUS = 'v1/action/status';
export const GET_PARTNER_SKU_STATISTICS = 'v1/partner/portfolio-coverage';
export const GET_APPAREL_CHANGE_HISTORY_LOGS = '/v1/change-history/list';
export const GENERATE_CHANGE_HISTORY_CSV = 'v1/change-history/csv/generate';
export const GET_PARTNER_PENDING_SKU_UPDATES = 'v1/partner-sku/pending-apply';
export const GET_CONFIG_CAT_SETTINGS_APPAREL = '/v1/settings/list';

// New Apparel APIs
export const GET_PARTNER_SKU_MAPPING_LIST_DETAILS = '/v1/partners/manufacturers/mapping';
export const GET_MANUFACTURER_SKU_MAPPING_LIST = '/v1/partners/skus';
export const GET_MANUFACTURER_SKU_UN_MAPPED_LIST = '/v1/partners/manufacturer-products/unmapped';
export const GET_MANUFACTURER_SKU_ALL_PRODUCTS_LIST = 'v1/products/manufacturer-skus';

/**
 * Workflow Configuration
 */
export const GET_WORKFLOW_TYPES = 'service/api/v1/integration-type/list';
export const WORKFLOW_CONFIG_FOR_PH_PREFIX = 'service/api/v1/integration-type/print-house';

/**
 * Suitable Shipment Methods proxy
 */
export const GET_SUITABLE_SHIPMENT_METHODS = '/service/api/v3/shipments/suitable-methods';
export const GET_SUITABLE_SHIPMENT_SPLIT_METHODS = '/service/api/v3/shipments/suitable-split-methods';

/**
 * Routing client url's
 */
export const GET_SUPPORTED_CLUSTERS_LOCATIONS = '/v1/cluster/locations';
export const GET_SUPPORTED_CLUSTERS_COUNTRIES = '/v1/cluster/countries';

/**
 * Apparel Availability url's (Apparel 2.0) // for LIVE
 */
export const GET_APPAREL_AVAILABILITY = '/v2/partner-mapped-product';
export const TOGGLE_PARTNER_SKU_ENABLE_DISABLE_STATUS = '/v2/partner-sku/toggle';
export const TOGGLE_PARTNER_SKU_IN_STOCK_OUT_OF_STOCK_STATUS = '/v2/partner-sku/stock-status';
export const GET_APPAREL_AVAILABILITY_STATS = '/v2/partner-sku/availability';
export const GET_APPAREL_AVAILABILITY_CHANGE_HISTORY = 'v2/partner-sku/history';
export const GET_APPAREL_AVAILABILITY_CSV_DOWNLOAD_LINK = '/v2/partner-mapped-product/csv';

/**
 * Capacity Management
 */
export const GET_ROUTING_CATALOG_TREE = '/service/api/v1/catalog/routing-tree';
export const GET_CAPACITY_UNITS = '/service/api/v1/print-house/capacity-unit';
export const CREATE_CAPACITY_UNIT = '/service/api/v1/print-house/capacity-unit/create';
export const UPDATE_CAPACITY_UNIT = '/service/api/v1/print-house/capacity-unit/update'; // /service/api/v1/print-house/capacity-unit/update/{catalogUid}
export const DELETE_CAPACITY_UNIT = '/service/api/v1/print-house/capacity-unit'; // /service/api/v1/print-house/capacity-unit/{catalogUid}
export const GET_PARTNER_CAPACITY_RULES = '/service/api/v1/print-house/product-capacity-rule/list';
export const CREATE_PARTNER_CAPACITY_RULES = '/service/api/v1/print-house/product-capacity-rule/create';
export const UPDATE_PARTNER_CAPACITY_RULES = '/service/api/v1/print-house/product-capacity-rule/update';
export const DELETE_PARTNER_CAPACITY_RULES = '/service/api/v1/print-house/product-capacity-rule/delete';
export const CREATE_BULK_CAPACITY_RULES = '/service/api/v1/print-house/product-capacity-rule/create-bulk';
export const GET_FILE_URL = '/service/api/v1/amazon/s3/url';

//
export const GET_PRODUCT_INFO_LIST = '/service/api/v1/product_platform_proxy/get_extended_product_info_list';
